<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject">
  <div fxFlex cdkDropListGroup>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex [class.layoutMobile]="viewPortMode === 'mobile'">
        <div *ngIf="viewPortMode !== 'mobile'" class="overlayLayout example-boundary" #mainBoundary
          (window:resize)="onResize($event)" (click)="selectComponent($event, undefined, undefined)">
          <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0VW" class="bgComponent">
            <div fxFlex *ngFor="let col of settingObject['columns'];let index = index" class="example-list"
              [class.highZIndex]="selectedColumn === index">
              <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
                <div fxFlex id="rowsmenu{{index}}" name="rowsmenu" class="example-box-row">
                  <div class="example-box" *ngFor="let cmp of col['components'];let subIndex = index" cdkDrag
                    cdkDragBoundary=".example-boundary"
                    [cdkDragFreeDragPosition]="{x: getX(cmp.x, mainBoundary, subIndex), y: getY(cmp.y, mainBoundary, subIndex)}"
                    (cdkDragEnded)="dragEnd($event, mainBoundary, cmp, index, subIndex)"
                    (cdkDragMoved)="dragMoved($event, mainBoundary, cmp)"
                    (cdkDragStarted)="dragStarted($event, index, subIndex)"
                    (cdkDragEntered)="dragEntered($event, index)" mwlResizable [enableGhostResize]="true"
                    [resizeCursorPrecision]="15" [resizeEdges]="componentsName[cmp.name].rules.resizeEdges"
                    (resizeStart)="selectComponent(undefined, index, subIndex)"
                    [resizeMin]="{w: (componentsName[cmp.name].rules.minSize.w * mainBoundary.offsetWidth) / 100, h: (componentsName[cmp.name].rules.minSize.h * mainBoundary.offsetWidth) / 100}"
                    [resizeMax]="{w: (componentsName[cmp.name].rules.maxSize.w * mainBoundary.offsetWidth) / 100, h: (componentsName[cmp.name].rules.maxSize.h * mainBoundary.offsetWidth) / 100}"
                    (resizing)="applyResizing($event, mainBoundary, cmp)"
                    [resizeRatio]="getRatio( componentsName[cmp.name].rules.ratioSize, cmp['notFixedRatio'], cmp['naturalRatioSize'])"
                    (resizeEnd)="onResizeEnd($event, mainBoundary, cmp, index, subIndex)"
                    [ngStyle]="{'z-index': getZ(cmp.z, index, subIndex), 'height' : getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize, cmp['notFixedRatio'], cmp['naturalRatioSize']), 'width' : cmp.w+'vw', 'opacity': (cmp.t/100), 'box-shadow': cmp.rx != 0 || cmp.ry != 0 || cmp.rz != 0 ? 'unset' : '', 'perspective': cmp.enableP ? (cmp.w < cmp.h ? cmp.h: cmp.w) + (cmp.py ? 'vh': 'vw') : 'none'}"
                    (click)="selectComponent($event, index, subIndex)"
                    [cdkDragDisabled]="(cmp.name === 'empty-click' || cmp.name === 'iframe') && enableEditor"
                    [id]="cmp.uid">
                    <div class="example-box-holder" [id]="cmp.uid + '-holder'"
                      [class.selectedComponent]="selectedColumn === index && selectedComponent === subIndex"
                      [class.cameraToggleComponent]="cmp.name === 'screenshare'"
                      [class.cameraMainComponent]="cmp.name === 'presenter-camera'"
                      [class.cameraComponent]="cameraComponents.includes(cmp.name) && cmp.name !== 'panelView' && cmp.name !== 'presenter-camera' && cmp.name !== 'screenshare'"
                      [class.audioComponent]="cmp.name === 'audio'" [class.partystreaming]="cmp.name === 'defaultvideo'"
                      [class.videoLibrary]="cmp.name === 'videolibrary'"
                      [class.example-box-transparent]="cmp.name === 'panelView' || cmp['name'] === 'audienceView'"
                      [class.imagegallery]="cmp.name === 'imagegallery'"
                      [class.editorClass]="cmp.name === 'editor' || cmp.name === 'empty-click' || cmp.name === 'picturewall' || cmp['name'] === 'audienceView' "
                      [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'"
                      [style.transform]="getTransform(cmp.uid, cmp.rx, cmp.ry, cmp.rz)"
                      [style.backgroundColor]="cmp.bgcolor">
                      <div class="example-box-handle" cdkDragHandle
                        [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'"
                        [class.pointerCursor]="cmp.name === 'empty-click' && enableEditor">
                        <div class="contentDataActions"
                          [class.contentDataActions-top]="((cmp.y * mainBoundary.offsetWidth)/100) < 20">
                          <!-- <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'editor'" (click)="cmp['preview'] = !(cmp['preview'])" matTooltip="{{'Edit' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon *ngIf="cmp['preview']">preview</mat-icon>
                              <mat-icon *ngIf="!cmp['preview']">edit</mat-icon>
                            </button> -->

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'editor'"
                            (click)="enableEditor = !enableEditor" matTooltip="{{'Editor' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picturewall'"
                            (click)="editPicturewall($event, index, subIndex)"
                            matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'defaultvideo'"
                            (click)="editDefaultvideo($event, index, subIndex)"
                            matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <!-- <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'audienceView'"
                          (click)="enableAudienceGrid = !enableAudienceGrid"
                          matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button> -->

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'panelView'"
                            (click)="enablePanelGrid = !enablePanelGrid" matTooltip="Show {{'Settings' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="tileComponents.includes(cmp.name)"
                            (click)="editTile($event, index, subIndex)" matTooltip="Select {{'Tile' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>select_all</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp.name === 'audio'"
                            (click)="editAudio($event, index, subIndex)" [class.selectedUser]="cmp.metadata.src !== ''"
                            matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp.name === 'videolibrary'"
                            (click)="editVideoLibrary($event, index, subIndex)"
                            [class.selectedUser]="cmp.metadata.id !== ''" matTooltip="Edit {{'Settings' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp.name === 'imagegallery'"
                            (click)="editImageGallery($event, index, subIndex)"
                            [class.selectedUser]="cmp.metadata.id !== ''" matTooltip="Edit {{'Settings' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picture'"
                            (click)="editPictureSetting($event, index, subIndex)"
                            matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'quuNowPlaying'"
                            (click)="editQuuNowPlaying($event, index, subIndex)"
                            matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'quuSongs'"
                            (click)="editQuuSongs($event, index, subIndex)" matTooltip="Edit {{'Settings' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'iframe'"
                            (click)="editIframeSetting($event, index, subIndex)"
                            matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button mat-icon-button color="primary"
                            *ngIf="cmp['name'] === 'empty-click' && cmp.shape === 'poly'"
                            (click)="editEmptyShape($event, index, subIndex)" matTooltip="Edit {{'Shape' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon *ngIf="enableEditor">layers</mat-icon>
                            <mat-icon *ngIf="!enableEditor">layers_clear</mat-icon>
                            <!-- <mat-icon>format_shapes</mat-icon> -->
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'iframe'"
                            (click)="editEmptyShape($event, index, subIndex)"
                            matTooltip="{{'Disable Drag' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon *ngIf="enableEditor">layers</mat-icon>
                            <mat-icon *ngIf="!enableEditor">layers_clear</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'empty-click'"
                            (click)="editEmptySetting($event, index, subIndex)"
                            matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>edit</mat-icon>
                          </button>


                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picture'"
                            (click)="editImage($event, index, subIndex)" matTooltip="Select {{'image' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>image</mat-icon>
                          </button>

                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picturewall'"
                            (click)="editPictureWallImages($event)" matTooltip="Select {{'image' | translate}}"
                            matTooltipClass="tooltip-red">
                            <mat-icon>image</mat-icon>
                          </button>

                          <!-- <span *ngIf="cmp['name'] === 'guest-camera' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span> -->
                          <!-- <button mat-icon-button color="primary"  *ngIf="cmp['name'] === 'guest-camera'" (click)="editcamera($event, index, subIndex)" [class.selectedUser]="cmp.metadata.name !== ''" matTooltip="Edit {{'Secondary' | translate}} {{'Camera' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="primary"  *ngIf="(cmp['name'] === 'presenter-camera') && type !== 'desktop-hls'" (click)="editcamera($event, index, subIndex)" [class.selectedUser]="cmp.metadata.name !== ''" matTooltip="Edit {{'Primary' | translate}} {{'Camera' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>edit</mat-icon>
                            </button> -->
                          <!-- <span *ngIf="cmp['name'] === 'billboard' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span> -->
                          <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'billboard'"
                            (click)="editBillboard($event, index, subIndex)"
                            matTooltip="{{'Select Billboard' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>select_all</mat-icon>
                          </button>
                          <button *ngIf="type !== 'desktop-hls'" mat-icon-button color="primary"
                            (click)="editActive($event, index, subIndex, !cmp['active'])"
                            matTooltip="{{!cmp['active'] ? 'Activate' : 'Deactivate'}}" matTooltipClass="tooltip-red">
                            <mat-icon *ngIf="!cmp['active']">visibility_off</mat-icon>
                            <mat-icon *ngIf="cmp['active']">visibility</mat-icon>
                          </button>
                          <button *ngIf="type !== 'desktop-hls' && cmp['name'] !== 'cloudlive'" mat-icon-button
                            color="primary" (click)="removeCmp($event, index, subIndex)"
                            matTooltip="{{'Delete' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>delete_forever</mat-icon>
                          </button>
                        </div>
                        <!--<div class="contentDataTitle">
                            <div>{{componentsName[cmp.name]['displayName']}}</div>
                             <div *ngIf="!(selectedColumn === index && selectedComponent === subIndex) ">x: {{cmp.x}}, y: {{cmp.y}}</div>
                            <div *ngIf="selectedColumn === index && selectedComponent === subIndex ">x: {{liveXY.x}}, y: {{liveXY.y}}</div>
                          </div>-->
                        <!-- <div class="contentDataBG" *ngIf="cameraComponents.includes(cmp.name)">
                            <mat-icon>videocam</mat-icon>
                          </div> -->
                        <div class="contentRealData"
                          [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'">
                          <div class="contentDataMain" [class.contentDataMainNoPadding]="cmp.name === 'audienceView'"
                            *ngIf="!((cmp['name'] === 'editor' || cmp['name'] === 'billboard' || cmp['name'] === 'iframe' || cmp['name'] === 'picturewall' || cmp['name'] === 'panelView' || cmp['name'] === 'picture'  || cmp['name'] === 'empty-click' || tileComponents.includes(cmp.name))) || (cmp['name'] === 'picturewall' && enableGrid) || (cmp['name'] === 'panelView' && enablePanelGrid)">
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                  <mat-label>Type</mat-label>
                                  <mat-select (selectionChange)="editAttribute(undefined, index, subIndex, 'type', $event.value)" [value]="cmp['type']" style="color: rgba(0,0,0,.54);
                                  margin-top: 8px;" >
                                    <mat-option value="" disabled>Select Type</mat-option>
                                    <mat-option *ngFor="let vidType of videoComponentTypes" [value]="vidType.value" >{{vidType.displayName | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput placeholder="{{ 'Video Url' | translate }}" [value]="cmp['url']" (change)="editAttribute($event, index, subIndex, 'url', $event.target.value)">
                                </mat-form-field>
                              </div> -->
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'cloudlive'">
                            <mat-form-field style="width: 100%;">
                              <input matInput type="number" min="0" placeholder="{{ 'CloudLive ID' | translate }}"
                                [(value)]="cmp['metadata']['id']"
                                (change)="editMetaNumericAttribute($event, index, subIndex, 'id', $event.target.value, 0, 1000000000000)">
                            </mat-form-field>
                          </div> -->
                            <!-- <div class="contentData" *ngIf="(cmp['name'] === 'presenter-camera') && type !== 'desktop-hls'">
                                <mat-icon *ngIf="!cmp['viewPublicChat']" (click)="cmp['viewPublicChat']=true" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['viewPublicChat']" (click)="cmp['viewPublicChat']=false" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <label style="width: 13vw" for="viewPublicChat{{subIndex}}"> {{'View public chat' | translate}}</label>
                              </div> -->
                            <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                              <mat-icon *ngIf="!cmp['notFixedRatio']" (click)="cmp['notFixedRatio']=true"
                                title="{{'Fixed Ratio 16/9' | translate}}" style="height: 30px;vertical-align: middle;">
                                check_box</mat-icon>
                              <mat-icon *ngIf="cmp['notFixedRatio']" (click)="cmp['notFixedRatio']=false"
                                title="{{'Fixed Ratio 16/9' | translate}}" style="height: 30px;vertical-align: middle;">
                                check_box_outline_blank</mat-icon>
                              <label style="width: 13vw" for="notFixedRatio{{subIndex}}"> {{'Fixed Ratio 16/9' |
                                translate}}</label>
                            </div>
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'camera'">
                                <mat-icon *ngIf="!cmp['enableAllowAllSpeak'] && !hasComponent('audienceView')" (click)="changeRowAllowAllSpeak($event, true, index, subIndex )" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['enableAllowAllSpeak'] && !hasComponent('audienceView')" (click)="changeRowAllowAllSpeak($event, false, index, subIndex )" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <mat-icon *ngIf="hasComponent('audienceView')" title="{{'This option cannot be enabled together with the audience view' | translate}}" style="height: 30px;vertical-align: middle;">browser_not_supported</mat-icon>
                                <label for="enableAllowAllSpeak{{subIndex}}" style="width: 13vw;">{{'All attendees can speak at the same time' | translate}}</label>
                              </div> -->
                            <div class="contentData"
                              style="bottom: 15px;position: absolute;font-size: 0.8rem;font-weight: bold;"
                              *ngIf="cmp['name'] === 'cloudlive' && cmp['type'] === 'streaming'">
                              Note: {{'The producer can retrieve the streaming link and share it with the publishers by
                              clicking on the CloudLive Stream icon inside the Controls bar of this session. Users who
                              enter this session will be able to view the running stream corresponding to the provided
                              link. Please note that if the Host of this session changes, the link will change.' |
                              translate}}
                            </div>
                            <div class="contentData"
                              style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;"
                              *ngIf="cmp['name'] === 'camera' && !settingObject['optionSettings']['enableRaiseHand']">
                              Note: {{'Enable the Hand Raise option for' | translate}} {{'attendees' | translate}} {{'to
                              be able to participate and appear in this component' | translate}}
                            </div>
                            <div class="contentData"
                              style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;"
                              *ngIf="cmp['name'] === 'screenshare' && componentsName[cmp.name].rules.requireCmp.includes('presenter-camera')">
                              Note: {{'This is the main camera area. When content is shared, the shared content appears
                              here.' | translate}}
                            </div>
                            <div class="contentData"
                              style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;"
                              *ngIf="cmp['name'] === 'presenter-camera' && hasComponent('screenshare')">
                              Note: {{'When content is shared, the main camera appears here.' | translate}}
                            </div>
                            <div class="contentData"
                              style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;text-align: center;width: 100%;"
                              *ngIf="cmp['name'] === 'audienceView'">
                              {{'The first 4000 attendees
                              will be able to see the Audience View' | translate}}
                            </div>
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Border Thickness' | translate }}" [(value)]="cmp['frame']" (change)="editNumericAttribute($event, index, subIndex, 'frame', $event.target.value, 0, 100)">
                                </mat-form-field>
                              </div> -->
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'screenshare'">
                                <mat-form-field type="text" style="width: 100%;">
                                    <input matInput placeholder="{{ 'Message' | translate }}" [value]="cmp['message']" (change)="editAttribute($event, index, subIndex, 'message', $event.target.value)">
                                </mat-form-field>
                              </div> -->

                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                                <mat-form-field type="text" style="width: 100%;">
                                    <input matInput placeholder="{{ 'Message' | translate }}" [value]="cmp['message']" (change)="editAttribute($event, index, subIndex, 'message', $event.target.value)">
                                </mat-form-field>
                              </div> -->


                            <div class="contentData"
                              *ngIf="cmp['name'] === 'presenter-camera' && type === 'desktop-hls'">
                              <mat-form-field style="width: 100%;">
                                <mat-label>Orientation</mat-label>
                                <mat-select
                                  (selectionChange)="editAttribute(undefined, index, subIndex, 'layout', $event.value)"
                                  [value]="cmp['layout']" style="color: rgba(0,0,0,.54);
                                  margin-top: 8px;">
                                  <mat-option value="" disabled>Select Orientation</mat-option>
                                  <mat-option [value]="'bestFit'">Best fit</mat-option>
                                  <mat-option [value]="'verticalPresentation'">Vertical presentation</mat-option>
                                  <mat-option [value]="'horizontalPresentation'">Horizontal presentation</mat-option>
                                  <!-- <mat-option [value]="'pip'" [disabled]="!((hasScreenShare && guestsTotal === 0) || (!hasScreenShare && guestsTotal === 1))">Picture in picture</mat-option> -->
                                  <mat-option [value]="'pip'" [disabled]="!hasTwoPipCamera">Picture in picture <span
                                      style="font-size:1rem;color:rgba(0,0,0,.38)">(Only available when the {{'Speaker'
                                      |
                                      translate}}/{{'Moderator' | translate}} Layout has exactly 2 camera
                                      components)</span></mat-option>
                                </mat-select>
                                <!-- <mat-hint>Picture in picture is only available when the {{'Speaker' | translate}}/{{'Moderator' | translate}} Layout has exactly 2 camera components</mat-hint> -->
                              </mat-form-field>
                            </div>
                            <div class="contentData" *ngIf="cmp['name'] === 'cloudlive' && type === 'desktop'">
                              <mat-form-field style="width: 100%;">
                                <mat-label>Type</mat-label>
                                <mat-select
                                  (selectionChange)="editAttribute(undefined, index, subIndex, 'type', $event.value)"
                                  [value]="cmp['type']" style="color: rgba(0,0,0,.54);
                                  margin-top: 8px;">
                                  <mat-option [value]="'meeting'">Meeting</mat-option>
                                  <mat-option [value]="'streaming'">Streaming</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" fxFlex
                              *ngIf="cmp['name'] === 'presenter-camera' && type === 'desktop-hls'">
                              <div fxFlex style="text-align: center;">
                                <img *ngIf="cmp['layout'] === 'bestFit'" src="assets/images/videolayout/bestFit.png"
                                  style="max-width: 100%;" [ngStyle]="{'width' : cmp.w+'vw'}">
                                <img *ngIf="cmp['layout'] === 'verticalPresentation'"
                                  src="assets/images/videolayout/verticalPresentation.png"
                                  [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}">
                                <img *ngIf="cmp['layout'] === 'horizontalPresentation'"
                                  src="assets/images/videolayout/horizontalPresentation.png"
                                  [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}">
                                <img [hidden]="!hasTwoPipCamera" *ngIf="cmp['layout'] === 'pip'"
                                  src="assets/images/videolayout/pip.png"
                                  [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}">
                                <!-- <img *ngIf="cmp['layout'] === 'pip'" src="assets/images/videolayout/pip.png"  [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}"> -->
                              </div>
                            </div>
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'picturewall'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Time delay' | translate }}" [(value)]="cmp['timer']" (change)="editNumericAttribute($event, index, subIndex, 'timer', $event.target.value, 3, 100000)">
                                </mat-form-field>
                              </div> -->
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                            <mat-form-field style="width: 100%;">
                              <input matInput type="number" min="1" placeholder="{{ 'Number of Rows' | translate }}"
                                [(value)]="cmp['gridSizeY']"
                                (change)="editNumericAttribute($event, index, subIndex, 'gridSizeY', $event.target.value, 1, 10)">
                            </mat-form-field>
                          </div>
                          <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                            <mat-form-field style="width: 100%;">
                              <input matInput type="number" min="1" placeholder="{{ 'Number of Columns' | translate }}"
                                [(value)]="cmp['gridSizeX']"
                                (change)="editNumericAttribute($event, index, subIndex, 'gridSizeX', $event.target.value, 1, 10)">
                            </mat-form-field>
                          </div>
                          <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                              <label style="width: 100%;color: grey;">
                                {{'The first ' | translate}} {{5000/(cmp['gridSizeX'] * cmp['gridSizeY']) | number: '1.0-0'}} {{' attendees
                                will be able to see the Audience View' | translate}}</label>
                          </div>
                          <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                            <label *ngIf="cmp['gridSizeX'] * cmp['gridSizeY'] > 10" style="width: 100%;color: red;">
                              {{'Up to 10 attendees can be displayed simultaneously' | translate}}</label>
                          </div> -->
                            <div class="contentData" *ngIf="cmp['name'] === 'panelView'">
                              <mat-form-field style="width: 100%;">
                                <input matInput type="number" min="1" max="12"
                                  placeholder="{{ 'Number of Panelists' | translate }}" [(value)]="cmp['totalCameras']"
                                  (change)="editNumericAttribute($event, index, subIndex, 'totalCameras', $event.target.value, 1, 12)">
                                <mat-hint align="start">{{'A panel can hold up to 12 panelists' | translate}}</mat-hint>
                              </mat-form-field>
                            </div>
                            <!-- <div class="contentData" *ngIf="cmp['name'] === 'panelView'">
                            <mat-form-field style="width: 100%;">
                              <input matInput type="number" min="1" max="12"
                                placeholder="{{ 'Maximum Number of Panelist per Row' | translate }}"
                                [(value)]="cmp['camerasPerRow']"
                                (change)="editNumericAttribute($event, index, subIndex, 'camerasPerRow', $event.target.value, 1, 12)">
                            </mat-form-field>
                          </div> -->
                          </div>
                          <ng-container *ngIf="tileComponents.includes(cmp.name) && cmp.metadata.id !== ''">
                            <app-iframe-url [iframeLink]="getTileLink(cmp.metadata.id)"
                              [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)"
                              [forEdit]="true"></app-iframe-url>
                          </ng-container>
                          <ng-container *ngIf="cmp['name'] === 'billboard' && cmp.metadata.id !== ''">
                            <app-slider [forEdit]="true" [bannerId]="cmp.metadata.id">
                            </app-slider>
                          </ng-container>
                          <ng-container *ngIf="cmp['name'] === 'iframe' && cmp.metadata.link !== ''">
                            <app-iframe-url [iframeLink]="cmp.metadata.link"
                              [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)"
                              [disablePointer]="!enableEditor" [forEdit]="false" [zIndex]="0"
                              [iframePadding]="cmp.frame">
                            </app-iframe-url>
                          </ng-container>
                          <ng-container *ngIf="cmp['name'] === 'picture'">
                            <img *ngIf="cmp.image" src="{{cmp.image}}" (error)="bgImageLoadedError( index, subIndex)"
                              (load)="bgImageLoaded($event, index, subIndex)" style="width:100%;height:100%">
                          </ng-container>
                          <ng-container *ngIf="cmp['name'] === 'empty-click' && cmp.shape === 'poly'">
                            <div style="height:100%;width:100%"
                              (click)="addPoint($event, mainBoundary, index, subIndex)">
                              <svg height="100%" width="100%" *ngIf="cmp.hasOwnProperty('svg')">
                                <polygon *ngIf="cmp.shape === 'poly'"
                                  [attr.points]="getShapePoint(mainBoundary, index, subIndex)"
                                  style="fill: rgb(102, 102, 102);stroke: rgb(51, 51, 51);stroke-width:1;opacity: 0.6; "
                                  [class.hasPointer]="selectedColumn === index && selectedComponent === subIndex && enableEditor">
                                  <title>{{cmp.title}}</title>
                                </polygon>
                                <ng-container
                                  *ngIf="selectedColumn === index && selectedComponent === subIndex && enableEditor">
                                  <circle *ngFor="let shp of cmp.svg;let circleindex = index"
                                    [attr.cx]="getPolyX(shp['x'], mainBoundary, index, subIndex)"
                                    [attr.cy]="getPolyY(shp['y'], mainBoundary, index, subIndex)" r="5"
                                    class="image-mapper-point" data-area-index="0" data-coord-index="0"
                                    style="fill: rgb(255, 255, 255); stroke: rgb(51, 51, 51); stroke-width: 1; opacity: 0.6; cursor: pointer;"
                                    (click)="onCircleClick($event, index, subIndex, circleindex)" cdkDrag
                                    (cdkDragEnded)="dragPolyEnd($event, mainBoundary, cmp, index, subIndex, circleindex)"
                                    (cdkDragStarted)="dragPolyStarted($event, mainBoundary, index, subIndex, circleindex)"
                                    (cdkDragMoved)="dragMoved($event, mainBoundary, cmp, index, subIndex, circleindex)">
                                  </circle>
                                </ng-container>
                              </svg>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="cmp['name'] === 'picturewall'  && !enableGrid && cmp.gridSizeX !== '' && cmp.gridSizeX !== undefined && cmp.gridSizeY !== '' && cmp.gridSizeY !== undefined">
                            <mat-grid-list cols="{{cmp.gridSizeX}}" rowHeight="1:1" [gutterSize]="cmp.spacing">
                              <!-- style="width:calc(100% - {{5*(cmp.gridSizeX-1)}}px)" style="background-color:rgb(255, 255, 255,0.3);"-->
                              <mat-grid-tile
                                *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX);let index = index"
                                style="background-color:rgb(102, 102, 102,0.6);">
                              </mat-grid-tile>
                              <!-- <mat-grid-tile *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX);let index = index"  [ngStyle]="{ 'border-right': (index+1)%cmp.gridSizeX ==0 ? '0px solid black' : '5px solid black' , 'border-bottom':(index+1) > (cmp.gridSizeX * (cmp.gridSizeY-1))  ? '0px solid black' : '5px solid black' }">
                                </mat-grid-tile> -->
                            </mat-grid-list>
                          </ng-container>
                          <ng-container
                            *ngIf="cmp['name'] === 'audienceView' && !enableAudienceGrid && cmp.gridSizeX !== '' && cmp.gridSizeX !== undefined && cmp.gridSizeY !== '' && cmp.gridSizeY !== undefined">
                            <app-panel-layout-component [cameraSettings]="{totalCameras: 10}">
                            </app-panel-layout-component>
                            <!-- <app-panel-layout-component [totalCameras]="10" [camerasList]="camerasList"></app-panel-layout-component> -->
                            <!-- <div class="grid-container">
                            <div class="camera-container"
                              [style.grid-template-columns]="getGridTemplateColumns(cmp.gridSizeX)"
                              [style.grid-template-rows]="'repeat(' + cmp.gridSizeY + ', ' + (cmp.h / +cmp.gridSizeY) - (0.5 * (cmp.gridSizeY - 1)) + 'vw' + ')'">
                              <ng-container
                                *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX); let idx = index">
                                <div id="cam-{{idx}}" class="flex-class">
                                  <div class="aspect-container default-back"
                                    style="background-color:rgb(102, 102, 102,0.6);">
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div> -->
                          </ng-container>
                          <ng-container
                            *ngIf="cmp['name'] === 'panelView' && !enablePanelGrid && cmp.totalCameras !== '' && cmp.totalCameras !== undefined && cmp.camerasPerRow !== '' && cmp.camerasPerRow !== undefined">
                            <app-panel-layout-component [cameraSettings]="{totalCameras: cmp.totalCameras}">
                            </app-panel-layout-component>
                            <!-- <div class="grid-container-panel">
                              <div class="camera-container"
                                [style.grid-template-columns]="getGridTemplateColumns(cmp.camerasPerRow)"
                                [style.grid-template-rows]="'repeat(' + Math.ceil(cmp.totalCameras / cmp.camerasPerRow) + ', ' + (cmp.h / +Math.ceil(cmp.totalCameras / cmp.camerasPerRow)) - (0.5 * (Math.ceil(cmp.totalCameras / cmp.camerasPerRow) - 1)) + 'vw' + ')'">
                                <ng-container
                                  *ngFor="let row of numberReturn(cmp.totalCameras); let idx = index">
                                  <div id="cam-{{idx}}" class="flex-class">
                                    <div class="aspect-container default-back"
                                      style="background-color:rgb(102, 102, 102,0.6);">
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div> -->
                          </ng-container>
                          <ng-container *ngIf="cmp['name'] === 'editor'">
                            <div [innerHTML]="cmp.data | safeHtml"></div>
                            <div *ngIf="enableEditor && selectedColumn === index && selectedComponent === subIndex"
                              class="editorPop"
                              [class.editorPop-left]="((cmp.x * mainBoundary.offsetWidth)/100) > (mainBoundary.offsetWidth/3)"
                              [class.editorPop-bottom]="((cmp.y * mainBoundary.offsetWidth)/100 + (cmp.h * mainBoundary.offsetWidth)/100) > mainBoundary.offsetHeight/2">
                              <ckeditor-block fxFlex [value]="cmp.data" [config]="editorConfig"
                                (textView)="editEditor(index, subIndex, 'data', $event)">
                              </ckeditor-block>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="resize-action"></div>
                      <div class="layout" data-aid="edit-box-layout">
                        <span class="component-labels"
                          [class.component-labels-top]="((cmp.y * mainBoundary.offsetWidth)/100) < 20">
                          <span *ngIf="type !== 'desktop-hls'" data-aid="display-name-label" class="label-comp-type">
                            <span *ngIf="cmp['name'] === 'guest-camera' && cmp.metadata.name !== ''"
                              class="sub-component-label" [ngStyle]="{ 'max-width' : (cmp.w - 1)+'vw'}">
                              {{cmp.metadata.name}} {{getCmpIndex(index, subIndex, cmp['name'])}}
                            </span>
                            <span *ngIf="!(cmp['name'] === 'guest-camera' && cmp.metadata.name !== '')"
                              class="sub-component-label" [ngStyle]="{ 'max-width' : (cmp.w - 1)+'vw'}">
                              <span
                                *ngIf="cmp.name !== 'empty-click' || (cmp.name === 'empty-click' && cmp.title ==='')">
                                {{componentsName[cmp.name]['displayName'] | translate}}
                              </span>
                              <span *ngIf="cmp.name === 'empty-click' && cmp.title !==''">
                                {{cmp.title}}
                              </span>
                              <span *ngIf="cmp.name === 'guest-camera'">
                                {{getComponentIndex(cmp.name, index, subIndex)}}
                              </span>
                              <!-- <span *ngIf="cmp.metadata && cmp.metadata.name !== ''">: {{cmp.metadata.name}}</span> -->
                            </span>
                          </span>
                          <span *ngIf="type === 'desktop-hls'" data-aid="display-name-label"
                            class="label-comp-type"><span class="sub-component-label"
                              [ngStyle]="{ 'max-width' : (cmp.w - 1) +'vw'}">{{componentsName[cmp.name]['hlsdisplayName']
                              | translate}} {{getCmpIndex(index, subIndex, cmp['name'])}}</span></span>
                        </span>
                        <span class="component-points">
                          <span *ngIf="!(cmp['name'] === 'empty-click' && cmp.shape === 'poly')">
                            <span data-direction-name="topLeft" class="topLeft"></span>
                            <span data-direction-name="top" class="top"></span>
                            <span data-direction-name="topRight" class="topRight"></span>
                            <span data-direction-name="right" class="right"></span>
                            <span data-direction-name="bottomRight" class="bottomRight"></span>
                            <span data-direction-name="bottom" class="bottom"></span>
                            <span data-direction-name="bottomLeft" class="bottomLeft">
                            </span>
                            <span data-direction-name="left" class="left">
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="viewPortMode === 'mobile'" class="overlayLayoutMobile example-boundary" #mainBoundary
          [ngStyle]="{'backgroundColor': bgColor, backgroundImage: 'url(' + pictureLink + ')', backgroundSize: 'cover', backgroundPosition: 'center'}"
          (click)="selectComponent($event, undefined, undefined)">
          <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0VW" class="bgComponent">
            <div fxFlex *ngFor="let col of settingObject['columns'];let index = index" class="example-list-mobile"
              [class.highZIndex]="selectedColumn === index">
              <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
                <div fxFlex id="rowsmenu{{index}}" name="rowsmenu" class="example-box-row" cdkDropList
                  (cdkDropListDropped)="drop($event)">
                  <div cdkDrag class="example-box-Mobile" *ngFor="let cmp of col['components'];let subIndex = index"
                    [ngStyle]="{'z-index': getZ(cmp.z, index, subIndex), 'height' : getMobileHeight(cmp.name, cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize, cmp['notFixedRatio']), 'width' : '100%'}"
                    (click)="selectComponent($event, index, subIndex)"
                    [class.selectedComponent]="selectedColumn === index && selectedComponent === subIndex"
                    [class.cameraToggleComponent]="cmp.name === 'screenshare'"
                    [class.cameraMainComponent]="cmp.name === 'presenter-camera'"
                    [class.cameraComponent]="cameraComponents.includes(cmp.name) && cmp.name !== 'panelView' && cmp.name !== 'presenter-camera' && cmp.name !== 'screenshare'"
                    [class.audioComponent]="cmp.name === 'audio'" [class.partystreaming]="cmp.name === 'defaultvideo'"
                    [class.videoLibrary]="cmp.name === 'videolibrary'"
                    [class.example-box-transparent]="cmp.name === 'panelView' || cmp['name'] === 'audienceView'"
                    [class.imagegallery]="cmp.name === 'imagegallery'"
                    [class.editorClass]="cmp.name === 'editor' || cmp.name === 'empty-click' || cmp.name === 'picturewall' || cmp['name'] === 'audienceView' "
                    [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'"
                    [cdkDragDisabled]="(cmp.name === 'empty-click' || cmp.name === 'iframe') && enableEditor">
                    <div class="example-box-handle"
                      [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'"
                      [class.pointerCursor]="cmp.name === 'empty-click' && enableEditor">
                      <div class="contentDataActions">
                        <button mat-icon-button color="primary" (click)="moveIndex($event, index, subIndex, false)"
                          [hidden]="subIndex === 0" matTooltip="{{'Move Up' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>arrow_upward</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" (click)="moveIndex($event, index, subIndex, true)"
                          [hidden]="subIndex === col['components'].length - 1" matTooltip="{{'Move Down' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>arrow_downward</mat-icon>
                        </button>
                        <!-- <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'editor'" (click)="cmp['preview'] = !(cmp['preview'])" matTooltip="{{'Edit' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon *ngIf="cmp['preview']">preview</mat-icon>
                              <mat-icon *ngIf="!cmp['preview']">edit</mat-icon>
                            </button> -->

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'editor'"
                          (click)="enableEditor = !enableEditor" matTooltip="{{'Editor' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picturewall'"
                          (click)="editPicturewall($event, index, subIndex)"
                          matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'defaultvideo'"
                          (click)="editDefaultvideo($event, index, subIndex)"
                          matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <!-- <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'audienceView'"
                          (click)="enableAudienceGrid = !enableAudienceGrid"
                          matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button> -->

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'panelView'"
                          (click)="enablePanelGrid = !enablePanelGrid" matTooltip="Show {{'Settings' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="tileComponents.includes(cmp.name)"
                          (click)="editTile($event, index, subIndex)" matTooltip="Select {{'Tile' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>select_all</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp.name === 'audio'"
                          (click)="editAudio($event, index, subIndex)" [class.selectedUser]="cmp.metadata.src !== ''"
                          matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp.name === 'videolibrary'"
                          (click)="editVideoLibrary($event, index, subIndex)"
                          [class.selectedUser]="cmp.metadata.id !== ''" matTooltip="Edit {{'Settings' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp.name === 'imagegallery'"
                          (click)="editImageGallery($event, index, subIndex)"
                          [class.selectedUser]="cmp.metadata.id !== ''" matTooltip="Edit {{'Settings' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picture'"
                          (click)="editPictureSetting($event, index, subIndex)"
                          matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'quuNowPlaying'"
                          (click)="editQuuNowPlaying($event, index, subIndex)"
                          matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'quuSongs'"
                          (click)="editQuuSongs($event, index, subIndex)" matTooltip="Edit {{'Settings' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'iframe'"
                          (click)="editIframeSetting($event, index, subIndex)"
                          matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="primary"
                          *ngIf="cmp['name'] === 'empty-click' && cmp.shape === 'poly'"
                          (click)="editEmptyShape($event, index, subIndex)" matTooltip="Edit {{'Shape' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon *ngIf="enableEditor">layers</mat-icon>
                          <mat-icon *ngIf="!enableEditor">layers_clear</mat-icon>
                          <!-- <mat-icon>format_shapes</mat-icon> -->
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'iframe'"
                          (click)="editEmptyShape($event, index, subIndex)" matTooltip="{{'Disable Drag' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon *ngIf="enableEditor">layers</mat-icon>
                          <mat-icon *ngIf="!enableEditor">layers_clear</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'empty-click'"
                          (click)="editEmptySetting($event, index, subIndex)"
                          matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>edit</mat-icon>
                        </button>


                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picture'"
                          (click)="editImage($event, index, subIndex)" matTooltip="Select {{'image' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>image</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picturewall'"
                          (click)="editPictureWallImages($event)" matTooltip="Select {{'image' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>image</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'billboard'"
                          (click)="editBillboard($event, index, subIndex)"
                          matTooltip="{{'Select Billboard' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>select_all</mat-icon>
                        </button>
                        <button *ngIf="type !== 'desktop-hls'" mat-icon-button color="primary"
                          (click)="editActive($event, index, subIndex, !cmp['active'])"
                          matTooltip="{{!cmp['active'] ? 'Activate' : 'Deactivate'}}" matTooltipClass="tooltip-red">
                          <mat-icon *ngIf="!cmp['active']">visibility_off</mat-icon>
                          <mat-icon *ngIf="cmp['active']">visibility</mat-icon>
                        </button>
                        <button *ngIf="type !== 'desktop-hls'" mat-icon-button color="primary"
                          (click)="removeCmp($event, index, subIndex)" matTooltip="{{'Delete' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>delete_forever</mat-icon>
                        </button>
                      </div>
                      <div class="contentRealData"
                        [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'">
                        <div class="contentDataMain" [class.contentDataMainNoPadding]="cmp.name === 'audienceView'"
                          *ngIf="!((cmp['name'] === 'editor' || cmp['name'] === 'billboard' || cmp['name'] === 'iframe' || cmp['name'] === 'picturewall' || cmp['name'] === 'panelView' || cmp['name'] === 'picture'  || cmp['name'] === 'empty-click' || tileComponents.includes(cmp.name))) || (cmp['name'] === 'picturewall' && enableGrid) || (cmp['name'] === 'panelView' && enablePanelGrid)">
                          <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                            <mat-icon *ngIf="!cmp['notFixedRatio']" (click)="cmp['notFixedRatio']=true"
                              title="{{'Fixed Ratio 16/9' | translate}}" style="height: 30px;vertical-align: middle;">
                              check_box</mat-icon>
                            <mat-icon *ngIf="cmp['notFixedRatio']" (click)="cmp['notFixedRatio']=false"
                              title="{{'Fixed Ratio 16/9' | translate}}" style="height: 30px;vertical-align: middle;">
                              check_box_outline_blank</mat-icon>
                            <label style="width: 13vw" for="notFixedRatio{{subIndex}}"> {{'Fixed Ratio 16/9' |
                              translate}}</label>
                          </div>
                          <div class="contentData"
                            style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;"
                            *ngIf="cmp['name'] === 'camera' && !settingObject['optionSettings']['enableRaiseHand']">
                            Note: {{'Enable the Hand Raise option for' | translate}} {{'attendees' | translate}} {{'to
                            be able to participate and appear in this component' | translate}}
                          </div>
                          <div class="contentData"
                            style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;"
                            *ngIf="cmp['name'] === 'screenshare' && componentsName[cmp.name].rules.requireCmp.includes('presenter-camera')">
                            Note: {{'This is the main camera area. When content is shared, the shared content appears
                            here.' | translate}}
                          </div>
                          <div class="contentData"
                            style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;"
                            *ngIf="cmp['name'] === 'presenter-camera' && hasComponent('screenshare')">
                            Note: {{'When content is shared, the main camera appears here.' | translate}}
                          </div>
                          <div class="contentData"
                            style="bottom: 0px;position: absolute;font-size: 0.8rem;font-weight: bold;text-align: center;width: 100%;"
                            *ngIf="cmp['name'] === 'audienceView'">
                            {{'The first 4000 attendees
                            will be able to see the Audience View' | translate}}
                          </div>


                          <div class="contentData" *ngIf="cmp['name'] === 'presenter-camera' && type === 'desktop-hls'">
                            <mat-form-field style="width: 100%;">
                              <mat-label>Orientation</mat-label>
                              <mat-select
                                (selectionChange)="editAttribute(undefined, index, subIndex, 'layout', $event.value)"
                                [value]="cmp['layout']" style="color: rgba(0,0,0,.54);
                                  margin-top: 8px;">
                                <mat-option value="" disabled>Select Orientation</mat-option>
                                <mat-option [value]="'bestFit'">Best fit</mat-option>
                                <mat-option [value]="'verticalPresentation'">Vertical presentation</mat-option>
                                <mat-option [value]="'horizontalPresentation'">Horizontal presentation</mat-option>
                                <!-- <mat-option [value]="'pip'" [disabled]="!((hasScreenShare && guestsTotal === 0) || (!hasScreenShare && guestsTotal === 1))">Picture in picture</mat-option> -->
                                <mat-option [value]="'pip'" [disabled]="!hasTwoPipCamera">Picture in picture <span
                                    style="font-size:1rem;color:rgba(0,0,0,.38)">(Only available when the {{'Speaker' |
                                    translate}}/{{'Moderator' | translate}} Layout has exactly 2 camera
                                    components)</span></mat-option>
                              </mat-select>
                              <!-- <mat-hint>Picture in picture is only available when the {{'Speaker' | translate}}/{{'Moderator' | translate}} Layout has exactly 2 camera components</mat-hint> -->
                            </mat-form-field>
                          </div>
                          <div fxLayout="row" fxLayout.lt-md="column" fxFlex
                            *ngIf="cmp['name'] === 'presenter-camera' && type === 'desktop-hls'">
                            <div fxFlex style="text-align: center;">
                              <img *ngIf="cmp['layout'] === 'bestFit'" src="assets/images/videolayout/bestFit.png"
                                style="max-width: 100%;" [ngStyle]="{'width' : cmp.w+'vw'}">
                              <img *ngIf="cmp['layout'] === 'verticalPresentation'"
                                src="assets/images/videolayout/verticalPresentation.png"
                                [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}">
                              <img *ngIf="cmp['layout'] === 'horizontalPresentation'"
                                src="assets/images/videolayout/horizontalPresentation.png"
                                [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}">
                              <img [hidden]="!hasTwoPipCamera" *ngIf="cmp['layout'] === 'pip'"
                                src="assets/images/videolayout/pip.png"
                                [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}">
                              <!-- <img *ngIf="cmp['layout'] === 'pip'" src="assets/images/videolayout/pip.png"  [ngStyle]="{'max-height' : (cmp.h - 6) +'vw', 'max-width' : cmp.w+'vw'}"> -->
                            </div>
                          </div>
                          <div class="contentData" *ngIf="cmp['name'] === 'panelView'">
                            <mat-form-field style="width: 100%;">
                              <input matInput type="number" min="1" max="12"
                                placeholder="{{ 'Number of Panelists' | translate }}" [(value)]="cmp['totalCameras']"
                                (change)="editNumericAttribute($event, index, subIndex, 'totalCameras', $event.target.value, 1, 12)">
                              <mat-hint align="start">{{'A panel can hold up to 12 panelists' | translate}}</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <ng-container *ngIf="tileComponents.includes(cmp.name) && cmp.metadata.id !== ''">
                          <app-iframe-url [iframeLink]="getTileLink(cmp.metadata.id)"
                            [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)"
                            [forEdit]="true"></app-iframe-url>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'billboard' && cmp.metadata.id !== ''">
                          <app-slider [forEdit]="true" [bannerId]="cmp.metadata.id">
                          </app-slider>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'iframe' && cmp.metadata.link !== ''">
                          <app-iframe-url [iframeLink]="cmp.metadata.link"
                            [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)"
                            [disablePointer]="!enableEditor" [forEdit]="false" [zIndex]="0" [iframePadding]="cmp.frame">
                          </app-iframe-url>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'picture'">
                          <img *ngIf="cmp.image" src="{{cmp.image}}" style="width:100%;height:100%">
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'empty-click' && cmp.shape === 'poly'">
                          <div style="height:100%;width:100%" (click)="addPoint($event, mainBoundary, index, subIndex)">
                            <svg height="100%" width="100%" *ngIf="cmp.hasOwnProperty('svg')">
                              <polygon *ngIf="cmp.shape === 'poly'"
                                [attr.points]="getShapePoint(mainBoundary, index, subIndex)"
                                style="fill: rgb(102, 102, 102);stroke: rgb(51, 51, 51);stroke-width:1;opacity: 0.6; "
                                [class.hasPointer]="selectedColumn === index && selectedComponent === subIndex && enableEditor">
                                <title>{{cmp.title}}</title>
                              </polygon>
                              <ng-container
                                *ngIf="selectedColumn === index && selectedComponent === subIndex && enableEditor">
                                <circle *ngFor="let shp of cmp.svg;let circleindex = index"
                                  [attr.cx]="getPolyX(shp['x'], mainBoundary, index, subIndex)"
                                  [attr.cy]="getPolyY(shp['y'], mainBoundary, index, subIndex)" r="5"
                                  class="image-mapper-point" data-area-index="0" data-coord-index="0"
                                  style="fill: rgb(255, 255, 255); stroke: rgb(51, 51, 51); stroke-width: 1; opacity: 0.6; cursor: pointer;"
                                  (click)="onCircleClick($event, index, subIndex, circleindex)" cdkDrag
                                  (cdkDragEnded)="dragPolyEnd($event, mainBoundary, cmp, index, subIndex, circleindex)"
                                  (cdkDragStarted)="dragPolyStarted($event, mainBoundary, index, subIndex, circleindex)"
                                  (cdkDragMoved)="dragMoved($event, mainBoundary, cmp, index, subIndex, circleindex)">
                                </circle>
                              </ng-container>
                            </svg>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="cmp['name'] === 'picturewall'  && !enableGrid && cmp.gridSizeX !== '' && cmp.gridSizeX !== undefined && cmp.gridSizeY !== '' && cmp.gridSizeY !== undefined">
                          <mat-grid-list cols="{{cmp.gridSizeX}}" rowHeight="1:1" [gutterSize]="cmp.spacing">
                            <mat-grid-tile
                              *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX);let index = index"
                              style="background-color:rgb(102, 102, 102,0.6);">
                            </mat-grid-tile>
                          </mat-grid-list>
                        </ng-container>
                        <ng-container
                          *ngIf="cmp['name'] === 'audienceView' && !enableAudienceGrid && cmp.gridSizeX !== '' && cmp.gridSizeX !== undefined && cmp.gridSizeY !== '' && cmp.gridSizeY !== undefined">
                        </ng-container>
                        <ng-container
                          *ngIf="cmp['name'] === 'panelView' && !enablePanelGrid && cmp.totalCameras !== '' && cmp.totalCameras !== undefined && cmp.camerasPerRow !== '' && cmp.camerasPerRow !== undefined">
                          <app-panel-layout-component [cameraSettings]="{totalCameras: cmp.totalCameras}">
                          </app-panel-layout-component>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'editor'">
                          <div [innerHTML]="cmp.data | safeHtml"></div>
                          <div *ngIf="enableEditor && selectedColumn === index && selectedComponent === subIndex"
                            class="editorPop"
                            [class.editorPop-left]="((cmp.x * mainBoundary.offsetWidth)/100) > (mainBoundary.offsetWidth/3)"
                            [class.editorPop-bottom]="((cmp.y * mainBoundary.offsetWidth)/100 + (cmp.h * mainBoundary.offsetWidth)/100) > mainBoundary.offsetHeight/2">
                            <ckeditor-block fxFlex [value]="cmp.data" [config]="editorConfig"
                              (textView)="editEditor(index, subIndex, 'data', $event)">
                            </ckeditor-block>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="layout" data-aid="edit-box-layout">
                      <span class="component-labels">
                        <span *ngIf="type !== 'desktop-hls'" data-aid="display-name-label" class="label-comp-type">
                          <span *ngIf="cmp['name'] === 'guest-camera' && cmp.metadata.name !== ''"
                            class="sub-component-label" [ngStyle]="{ 'max-width' : (cmp.w - 1)+'vw'}">
                            {{cmp.metadata.name}} {{getCmpIndex(index, subIndex, cmp['name'])}}
                          </span>
                          <span *ngIf="!(cmp['name'] === 'guest-camera' && cmp.metadata.name !== '')"
                            class="sub-component-label" [ngStyle]="{ 'max-width' : (cmp.w - 1)+'vw'}">
                            <span *ngIf="cmp.name !== 'empty-click' || (cmp.name === 'empty-click' && cmp.title ==='')">
                              {{componentsName[cmp.name]['displayName'] | translate}}
                            </span>
                            <span *ngIf="cmp.name === 'empty-click' && cmp.title !==''">
                              {{cmp.title}}
                            </span>
                            <span *ngIf="cmp.name === 'guest-camera'">
                              {{getComponentIndex(cmp.name, index, subIndex)}}
                            </span>
                            <!-- <span *ngIf="cmp.metadata && cmp.metadata.name !== ''">: {{cmp.metadata.name}}</span> -->
                          </span>
                        </span>
                        <span *ngIf="type === 'desktop-hls'" data-aid="display-name-label" class="label-comp-type"><span
                            class="sub-component-label"
                            [ngStyle]="{ 'max-width' : (cmp.w - 1) +'vw'}">{{componentsName[cmp.name]['hlsdisplayName']
                            | translate}} {{getCmpIndex(index, subIndex, cmp['name'])}}</span></span>
                      </span>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>